<template>
  <div class="container">
    <div class="row">
      <div class="col-md-8 mx-auto mt-5">
        <h3>Thank You For Your Inquiry!</h3>
        <p>
          We aim to provide the most comprehensive, coherent, and customizable regulatory alerts in the world.
          We will review your request and get back to you ASAP. Thanks for your business!
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SupportConfirmation"
}
</script>

<style scoped>

</style>